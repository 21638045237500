import { User } from "../reclaim-api/Users";

export const getMonogram = (name?: string, email?: string): string | null => {
  if (!name && !email) return null;
  const chunks = !!name
    ? name
        .replace(/[^\w^\s]/gi, "")
        .trim()
        .split(/\s+/)
    : email
        ?.replace(/[^\w^\-^\.]/gi, "")
        .replace(/^[\-\.]/gi, "")
        .split(/[-.]/) || [];
  return chunks.map((chunk) => (chunk[0] || "").toUpperCase()).join("");
};

export const getUserMonogram = (user: User | null): string | null => {
  if (!user || !!user.avatarUrl) return null;
  return getMonogram(user.name, user.email);
};
